import { SquarePaymentsForm, CreditCardInput } from "react-square-web-payments-sdk";
import { absUrl } from "../util/func";

const Payment = () => {
    return (
        <main style={{ backgroundImage: `url(${absUrl('/uploads/089_A1139_D9_F1_41_BD_BEBB_461_CE_1_A7_D1_F3_504977983d.jpeg')})` }}>
            <div className="checkout--content glass square">
                <h2 className="checkout--content--header">My Cart</h2>
                <div>
                    <SquarePaymentsForm
                        applicationId={process.env.REACT_APP_APPID}
                        locationId="L2738YY5ZPMZA"
                        cardTokenizeResponseReceived={async (token, buyer) => {
                            console.info(JSON.stringify(token, null, 2))
                        }}
                    // createVerificationDetails={() => ({
                    //     amount: '1.00',
                    //     /* collected from the buyer */
                    //     billingContact: {
                    //         addressLines: ['123 Main Street', 'Apartment 1'],
                    //         familyName: 'Doe',
                    //         givenName: 'John',
                    //         countryCode: 'GB',
                    //         city: 'London',
                    //     },
                    //     currencyCode: 'GBP',
                    //     intent: 'CHARGE',
                    // })}
                    >
                        <CreditCardInput />
                    </SquarePaymentsForm>
                </div>
            </div>
        </main>
    )
}

export default Payment;