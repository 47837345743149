export const absUrl = (url) => {
    return process.env.REACT_APP_BASEURL + url;
}

export const placeholderUrl = (qs) => {
    return `https://via.placeholder.com/${qs}`
}

export const productUrl = (id) => {
    return `/Products/${id}`
}