import React, { useState, useContext } from "react";
import { Button, FormControl, FormControlLabel, FormLabel, RadioGroup, Radio, Slider } from "@mui/material";

import { absUrl } from "../util/func";
import { AppContext } from '../context/AppProvider';

export default function Scent({ scent, setText, setOpen }) {
    const { cart: [cartData, setCartData] } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(false);
    const [count, setCount] = useState(1);
    const [pid, setPid] = useState(scent.Products[0].id);
    const { Image, Label, Description, Products } = scent;

    const handleOpen = () => {
        setIsOpen(prev => !prev);

        if (!isOpen) {
            setCount(1);
        }
    }

    const addToCart = () => {
        const { id, Name, Price, Description: pDescription } = Products.find(f => f.id === pid);
        const lineItems = cartData.line_items;
        let index = lineItems.findIndex((item => item.id === id));

        if (index === -1) {
            lineItems.push({
                id: id,
                name: Name,
                quantity: count,
                base_price_money: {
                    amount: Price,
                    currency: "USD"
                }
            })
        } else {
            lineItems[index].quantity = Number(lineItems[index].quantity) + count;
        }

        setCartData({
            ...cartData,
            line_items: lineItems
        });

        localStorage.setItem("cart", JSON.stringify(cartData))
        setCount(1);
        setOpen(true);
        setText(`Added ${count} ${Name} ${pDescription} to your cart!`);
    }

    return (
        <div className="scent-card glass square">
            <img className="scent-card_image" src={absUrl(Image.url)} alt={Label} />
            <div className="scent-card-content">
                <div className="scent-card-content_title">{Label}</div>
                <div className="scent-card-content_description">{Description}</div>
                <Button onClick={() => handleOpen()} size="small" variant="outlined">
                    {isOpen ? ('Close') : ('Buy Now')}
                </Button>
            </div>
            {isOpen && (
                <div className="scent-card-details">
                    <FormControl fullWidth>
                        <RadioGroup
                            aria-labelledby="scent-card-style-label"
                            value={pid}
                            name="scent-card-style-group"
                            sx={{ marginTop: '1rem' }}
                            onChange={(e) => { setPid(e.target.value); }}
                        >
                            {Products.map(product => (
                                <FormControlLabel key={product.id} value={product.id} control={<Radio sx={{ color: 'white', marginBottom: '1rem' }} />} label={`${product.Description} ($${product.Price})`} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel
                            id="scent-card-count-label"
                            sx={{ color: 'white' }}
                        >
                            Count: {count}
                        </FormLabel>
                        <Slider
                            aria-label="Count"
                            defaultValue={1}
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={10}
                            onChange={(e) => { setCount(e.target.value) }}
                        />
                    </FormControl>
                    <Button onClick={() => addToCart()} size="small" variant="outlined">Add to Cart</Button>
                </div>
            )}
        </div>
    )
}