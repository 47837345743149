import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Alert, Button, List, ListItem, ListItemText } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useQuery } from 'react-query';

import { AppContext } from '../context/AppProvider';
import { absUrl } from '../util/func';
import './Checkout.scss';

const Checkout = () => {
    const { cart: [cartData, setCartData] } = useContext(AppContext);
    const history = useHistory();

    const checkoutData = async () => await (await fetch(absUrl('/Checkout'))).json();
    const chck = useQuery('checkout', checkoutData)

    const updateProductCount = (id, isSubtract) => {
        const lineItems = cartData.line_items;
        let index = lineItems.findIndex((item => item.id === id));
        let item = lineItems[index]

        if (isSubtract && item.quantity > 1) item.quantity -= 1;
        if (!isSubtract) item.quantity += 1;

        setCartData(prevState => {
            return {
                ...prevState,
                line_items: lineItems
            }
        })
    }

    const removeItem = (id) => {
        const lineItems = cartData.line_items.filter(i => i.id !== id);

        setCartData(prevState => {
            return {
                ...prevState,
                line_items: lineItems
            }
        })
    }

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cartData))
    }, [cartData])

    const Submit = () => {
        history.push('/Payment'); // go to payment page, payment page will use the context to retrieve items
    }

    if (chck.isLoading) return "Loading checkout information...";
    if (chck.error) return `An error has occured: ${chck.error.message}`;

    const subtotal = (cartData.line_items.reduce((total, num) => { return total + (num.base_price_money.amount * num.quantity); }, 0));
    const total = subtotal + (subtotal * Number(chck.data.tax)) + Number(chck.data.shipping);
    const background = chck.data.background.url;

    return (
        <main style={{ backgroundImage: `url(${absUrl(background)})` }}>
            <div className="checkout--content glass square">
                <h2 className="checkout--content--header">My Cart</h2>
                <Alert sx={{ marginBottom: '1rem' }} severity="warning">Payments are currently offline and you will be unable to complete orders. Please check back later for online ordering!</Alert>
                {(!cartData.line_items || cartData.line_items.length === 0) && (
                    <p>No items in cart</p>
                )}
                <List>
                    {cartData.line_items.map(item => (
                        <ListItem
                            key={item.id}
                            secondaryAction={
                                <>
                                    <IconButton aria-label="remove" onClick={() => updateProductCount(item.id, true)}>
                                        <RemoveIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    {item.quantity}
                                    <IconButton aria-label="add" onClick={() => updateProductCount(item.id)}>
                                        <AddIcon style={{ color: 'white' }} />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => removeItem(item.id)}>
                                        <DeleteIcon style={{ color: 'white' }} />
                                    </IconButton>
                                </>
                            }>

                            <ListItemText
                                primary={<strong><Link to={`/Products/${item.id}`}>{item.name}</Link></strong>}
                                secondary={`$${(item.base_price_money.amount)?.toFixed(2)} per (Total: $${(item.base_price_money.amount * item.quantity)?.toFixed(2)})`}
                            />
                        </ListItem>
                    ))}
                </List>
                <Grid container style={{ margin: "1rem 0 1rem 0", textAlign: "right" }}>
                    <Grid item md={7} xs={0}></Grid>
                    <Grid item md={2} xs={6}>
                        Subtotal:
                    </Grid>
                    <Grid item md={3} xs={6}>
                        {`$${subtotal.toFixed(2)}
                            (${(cartData.line_items.reduce((total, num) => {
                            return total + (1 * num.quantity);
                        }, 0))} items)`}
                    </Grid>
                    <Grid item md={7} xs={0}></Grid>
                    <Grid item md={2} xs={6}>Tax:</Grid>
                    <Grid item md={3} xs={6}>{(chck.data.tax * 100).toFixed(2)}%</Grid>
                    <Grid item md={7} xs={0}></Grid>
                    <Grid item md={2} xs={6}>S&H:</Grid>
                    <Grid item md={3} xs={6}>${chck.data.shipping.toFixed(2)}</Grid>
                    <Grid item md={7} xs={0}></Grid>
                    <Grid item md={2} xs={6}>Total:</Grid>
                    <Grid item md={3} xs={6}>${total.toFixed(2)}</Grid>
                </Grid>
                <div className="checkout--content--actions">
                    <Button variant="outlined" onClick={() => history.goBack()}>Back</Button>
                    {/* {cartData.line_items.length > 0 && <Button variant="contained" onClick={() => Submit()}>Checkout</Button>} */}
                </div>
            </div>
        </main>
    )
}

export default Checkout;