import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query';
import Checkout from './pages/Checkout';
import ProductsPage from './pages/ProductsPage';
import Nav from './components/Nav';
import AppProvider from './context/AppProvider';
import './index.scss';
import Payment from './pages/Payment';

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <BrowserRouter>
          <Nav />
          <Switch>
            <Route path="/" exact component={App} />
            <Route path="/Checkout" exact component={Checkout} />
            <Route path="/Payment" exact component={Payment} />
            <Route path="/Products" exact component={ProductsPage} />
            <Route path="*" component={App} />
          </Switch>
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
