import { useQuery } from 'react-query';
import { useState } from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { absUrl, placeholderUrl } from '../util/func';
import Footer from '../components/Footer';
import './Content.scss';

const Content = () => {
    const [form, setForm] = useState({ name: '', email: '', subject: '', message: '' })
    const history = useHistory();

    const handleInputChange = (ev) => {
        ev.persist();
        setForm(values => ({
            ...values,
            [ev.target.name]: ev.target.value
        }))
    }

    const submitHandler = (e) => {
        e.preventDefault();
        fetch(absUrl("/messages"), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(form)
        })
            .then(res => res.json())
            .catch((err) => {
                // TODO: Submit error to backend
                console.error('Error sending message');
            })
            .finally(() => {
                setForm({ name: '', email: '', subject: '', message: '' })
            })
    }

    const pageData = async () => await (await fetch(absUrl('/ok-candles-app'))).json();
    const { isLoading, error, data } = useQuery('page', pageData)

    if (isLoading) return "Loading...";
    if (error) return `An error has occured: ${data.error.message}`

    let headerImgUrl = placeholderUrl("1920x1080");
    if (data.HeaderImage) headerImgUrl = data.HeaderImage.url;

    return (
        <>
            <header id="Home" style={{ backgroundImage: `url(${absUrl(headerImgUrl)})` }}>
                <div className="glass text">
                    <h1>{data.Title}</h1>
                    <p>{data.Subtitle}</p>
                    <Button variant="contained" size="small" onClick={() => history.push('/Products')}>Get The Perfect Scent Now!</Button>
                </div>
            </header>
            <section id="Testimonials">
                <div className="section-content">
                    <div className="testimonials">
                        {data.Testimonials.map(t => (
                            <figure key={t.id}>
                                <blockquote>
                                    <p>
                                        {t.Content}
                                    </p>
                                </blockquote>
                                <figcaption>-{t.Name}, <cite>{t.Title}</cite></figcaption>
                            </figure>
                        ))}
                    </div>
                </div>
            </section>
            <section id="Trending">
                <h2>Trending Scents</h2>
                <div className="section-content">
                    <div className="trending">
                        {data.Trending.map(t => (
                            <a key={t.id} href="/Products">
                                <img className="productimg" src={`${absUrl(t.Image.url)}`} alt="Placeholder" />
                            </a>
                        ))}
                    </div>
                </div>
            </section>
            <section id="Info">
                <div className="section-content">
                    {data.InfoSections.map(item => (
                        <div className="section-content-infoblock" key={item.id}>
                            <img src={`${absUrl(item.SplashImage.url)}`} alt="Placeholder" width="300" height="300" />
                            <div className="section-content-infoblock-content">
                                <h3>{item.Title}</h3>
                                <p>{item.Content}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section id="Mission">
                <h2>The Mission</h2>
                <div className="section-content">
                    <h4>Our Story</h4>
                    <p className="about-text glass text">
                        {data.AboutUs}
                    </p>
                    <h4>Send A Message</h4>
                    <form className="form glass" onSubmit={submitHandler}>
                        <label className="form-label" htmlFor="name">
                            Name:
                        </label>
                        <input className="form-input" type="text" name="name" placeholder="Name" value={form.name} onChange={handleInputChange} />
                        <label className="form-label" htmlFor="email">
                            Email:
                        </label>
                        <input className="form-input" type="email" name="email" placeholder="Email" value={form.email} onChange={handleInputChange} />
                        <label className="form-label" htmlFor="subject">
                            Subject:
                        </label>
                        <input className="form-input" type="text" name="subject" placeholder="Subject" value={form.subject} onChange={handleInputChange} />
                        <label className="form-label" htmlFor="message">
                            Message:
                        </label>
                        <textarea className="form-input" name="message" placeholder="Message" value={form.message} onChange={handleInputChange}></textarea>
                        <input className="form-submit" type="submit" value="Send" />
                    </form>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Content;