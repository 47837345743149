import { createContext, useState } from "react";
import { absUrl } from "../util/func";

export const AppContext = createContext(null);

export default ({ children }) => {
    const [cart, setCart] = useState(localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : {
        line_items: []
    });

    const [token, setToken] = useState();

    async function getData() {
        fetch('/token', {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            console.log('res:', res);
            return res;
        }).then(data => {
            console.log('data:', data);
            // setToken(data);
        })
    }

    // setToken(data);

    // get token from backend and update state

    const store = {
        cart: [cart, setCart],
        token: [token, setToken]
    }

    return (
        <AppContext.Provider value={store}>
            {children}
        </AppContext.Provider>
    )
}

/*
// Context example:
{
    idempotency_key: "env variable",
    reference_id: "some unique identifier"
    line_items: [
        {
            name: "item name",
            quantity: "1",
            base_price_money: {
                amount: 5,
                currency: "USD"
            }
        }
    ],
    fulfillments: [
        {
            type: "PICKUP",
            state: "PROPOSED",
            pickup_details: {
                recipient: {
                    display_name: "customer name",
                    email_address: "customer email",
                    phone_number: "1-555-555-5555"
                },
                expires_at: "2021-12-12",
                auto_complete_duration: "PODT1HOS",
                schedule_type: "SCHEDULED",
                pickup_at: "2021-12-11",
                prep_time_duration: "PT20M",
                note: "some note for the order"
            }
        }
    ]
}
*/