import React, { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import './Nav.scss';
import { AppContext } from '../context/AppProvider';

export default function Nav() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const { cart: [cartData, setCartData] } = useContext(AppContext);

    const handleToggleNav = () => {
        setIsNavOpen(!isNavOpen);
    }

    return (
        <>
            <nav className="glass nav large">
                <ul>
                    <li><a href="/Home" className="brand">Home</a></li>
                    <li><a href="/Products">Products</a></li>
                    <li><a href="/Checkout">Cart <em>({(cartData.line_items.reduce((total, num) => { return total + (1 * num.quantity); }, 0))} Items)</em></a></li>
                </ul>
            </nav>
            <nav className="glass nav small">
                <IconButton aria-label="Menu" onClick={handleToggleNav}>
                    <MenuOutlinedIcon color='secondary' id="dropdown-button" />
                </IconButton>
                {isNavOpen &&
                    <ul className="dropdown-items">
                        <li><a href="/" onClick={handleToggleNav}>Home</a></li>
                        <li><a href="/Products" onClick={handleToggleNav}>Products</a></li>
                        <li><a href="/Checkout">Cart <em>({(cartData.line_items.reduce((total, num) => { return total + (1 * num.quantity); }, 0))} Items)</em></a></li>
                    </ul>
                }
            </nav>
        </>
    );
};