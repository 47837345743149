import React from 'react';
import { useQuery } from 'react-query';

import { absUrl } from '../util/func';
import './Scents.scss';
import Scent from './Scent';
import { Alert } from '@mui/material';

export default function Products({ setText, setOpen }) {
    const scent = useQuery('products', async () => await (await fetch(absUrl("/Scents"))).json())

    if (scent.isLoading) return "Loading Products...";
    if (scent.error) return `An error has occured: ${scent.error.message}`;

    return (
        <section id="Products">
            <h2>Products</h2>
            <div className="section-content">
                <Alert sx={{ marginBottom: '1rem' }} severity="warning">Payments are currently offline and you will be unable to complete orders. Please check back later for online ordering!</Alert>
                {scent.data.map(scent => (
                    <Scent key={scent.id} scent={scent} setText={setText} setOpen={setOpen} />
                ))}
            </div>
        </section>
    );
};