import React, { useState } from 'react';

import Scents from '../components/Scents';
import Footer from '../components/Footer';
import Notification from '../components/Notification';

export default function ProductsPage() {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');

    return (
        <>
            <Scents setText={setText} setOpen={setOpen} />
            <Notification text={text} open={open} setOpen={setOpen} />
            <Footer />
        </>
    );
};