import './Footer.scss';

export default function Footer() {
    return (
        <footer className="glass footer">
            <div className="social-media">
                <a href="https://www.facebook.com/OK-Candles-100656759056951" target="blank"><img src="https://strapi.server.pitch201.com/uploads/FB_bac39c7c05.png" className="icon" alt="Facebook" title="Facebook" /></a>
                <a href="https://www.instagram.com/o_k_candles/" target="blank"><img src="https://strapi.server.pitch201.com/uploads/ig_4c749aa62e.png" className="icon" alt="Instagram" title="Instagram" /></a>
                <a href="https://www.tiktok.com/@ok_candles?" target="blank"><img src="https://strapi.server.pitch201.com/uploads/Tick_Tock_18c29bfc73.png" className="icon" alt="TikTok" title="TikTok" /></a>
            </div>
            <p className="createdby">
                (c) ESInnovations, 2021
            </p>
        </footer>
    )
}